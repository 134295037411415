<template>
  <div class="appointments-content-page">
    <div class="page-subtoolbar">
      <div class="columns">
        <div class="column">
          <div class="input-group">
            <input type="text" class="form-input" v-model.trim="filter.search"
                   placeholder="Pesquisar paciente..." @change="formatFilterSearch">
            <button class="btn btn-warning btn-icon btn-action input-group-btn"
                    @click="filter.search = ''" v-if="filter.search !== ''">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
            <button class="btn btn-neutral btn-icon btn-action input-group-btn" v-else>
              <fa-icon :icon="['fal', 'search']"/>
            </button>
          </div>
        </div>
        <div class="column" style="flex: 0 0 3rem">
          <div class="btn-group btn-group-block">
<!--            <button class="btn btn-gray btn-icon btn-block tooltip tooltip-bottom"-->
<!--                    data-tooltip="Lista de espera" @click="openWaitingList">-->
<!--              <fa-icon :icon="['fal', 'user-clock']" size="3x"/>-->
<!--            </button>-->
<!--            <button-->
<!--              class="btn btn-icon btn-block tooltip tooltip-bottom"-->
<!--              :class="hasClipboardData ? 'btn-info' : 'btn-gray'"-->
<!--              data-tooltip="Colar como encaixe"-->
<!--              @click="openSlotModal"-->
<!--              :disabled="!hasClipboardData"-->
<!--            >-->
<!--              <fa-icon :icon="['fal', 'paste']" size="3x"/>-->
<!--            </button>-->
          </div>
        </div>
        <div class="column" style="flex: 0 0 9rem;">
          <div class="btn-group btn-group-block">
            <button class="btn btn-icon tooltip tooltip-bottom-left"
                    data-tooltip="Horários vagos"
                    :class="filter.hours === hoursFilter.FREE
                  ? 'btn-warning' : 'btn-gray'"
                    @click="filter.hours = hoursFilter.FREE">
              <fa-icon :icon="['fal', 'calendar-check']" size="3x"/>
            </button>
            <button class="btn btn-icon tooltip tooltip-bottom-right"
                    data-tooltip="Pacientes agendados"
                    :class="filter.hours === hoursFilter.TAKEN
                  ? 'btn-warning' : 'btn-gray'"
                    @click="filter.hours = hoursFilter.TAKEN">
              <fa-icon :icon="['fal', 'calendar-times']" size="3x"/>
            </button>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!loading">
      <div v-if="isArrivalOrder" class="arrival-order-info">
        <fa-icon :icon="['fal', 'clock']" />
        <span>Agenda por ordem de chegada</span>
        <strong class="chip ml-2 bg-info">
          {{ arrivalOrderRange.start }} - {{ arrivalOrderRange.end }}
        </strong>
      </div>

      <div class="empty" v-if="filteredAppointments.length === 0 && !isArrivalOrder">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'calendar-alt']" size="3x"/>
        </div>
        <p class="empty-title h5">Sem agenda</p>
        <p class="empty-subtitle">Não existe configuração de agenda para esta data</p>
      </div>
      <template v-else>
        <table class="table table-hover">
          <tbody>
          <table-row v-for="(item, i) in filteredAppointments"
                     @deleteMultipleAppointments="deleteMultipleAppointments"
                     @openModalInsuranceList="openModalInsuranceList"
                     :data="item" :key="i"
          />
          </tbody>
        </table>
      </template>
      <dx-modal title="Excluir múltiplos agendamentos" id="modal-appointment-delete" size="lg"
                v-model="deleteMultipleAppointmentsModal.show">
        <div class="columns">
          <table class="table table-hover">
            <thead>
            <tr>
              <th width="30px">
                <div class="form-group">
                  <label class="form-checkbox">
                    <input type="checkbox" v-model="deleteMultipleAppointmentsModal.selectAll"
                           @click="selectAllAppointments">
                    <i class="form-icon"></i>
                  </label>
                </div>
              </th>
              <th width="120px">Data</th>
              <th>Paciente</th>
              <th>Faturamento</th>
              <th>Agenda</th>
              <th>Convênio</th>
              <th>Tipo</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(appointment, i) in deleteMultipleAppointmentsModal.appointments" :key="i">
              <td width="30px">
                <div class="form-group">
                  <label class="form-checkbox">
                    <input type="checkbox" v-model="appointment.checked"
                           @change="checkDeleteAppointmentItem"
                           :disabled="appointment.hasAccount">
                    <i class="form-icon"></i>
                  </label>
                </div>
              </td>
              <td width="120px">{{ formatDateTime(appointment.startDate) }}</td>
              <td>{{ appointment.patient.name }}</td>
              <td>{{ appointment.hasAccount ? 'Faturado' : 'Não faturado' }}</td>
              <td>{{ appointment.schedule.name }}</td>
              <td>{{ appointment.insurance.name }}</td>
              <td>{{ getAppointmentType(appointment.type) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <template slot="footer">
          <button class="btn btn-error mr-1" @click="deleteAppointments">Excluir</button>
          <button class="btn" @click="closeDeleteMultipleAppointmentsModal">Sair</button>
        </template>
      </dx-modal>
    </template>
    <div class="loading loading-lg mt-2" v-else></div>
    <waiting-list-modal :show="waitingListModal.show"
                        v-if="waitingListModal.show"
                        @close="waitingListModal.show = false" />
    <dx-modal title="Lista de convênios"
              id="modal-insurance-info"
              size="sm"
              :value="modalInsuranceInfo.show"
              @input="modalInsuranceInfo.show = false">
      <template>
        <span>Agenda:</span>
        <strong class="text-primary ml-1">{{ modalInsuranceInfo.scheduleName }}</strong>
        <div class="divider" />
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Convênios</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in modalInsuranceInfo.insurances" :key="i">
            <td>{{ item }}</td>
          </tr>
          </tbody>
        </table>
      </template>
      <template slot="footer">
        <button class="btn" @click="modalInsuranceInfo.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import {
  mapActions, mapMutations, mapState, mapGetters,
} from 'vuex';
import AblyService from '@/services/ably';
import formMixin from 'src/mixins/form';
import fuzzy from 'fuzzy';
import moment from 'moment';
import StringMask from 'string-mask';
import * as status from 'src/data/appointment-statuses';
import * as types from 'src/data/appointment-types';
import TableRow from './TableRow.vue';
import WaitingListModal from './modals/waitingList/Modal.vue';

const hoursFilter = {
  ALL: 'all',
  FREE: 'free',
  TAKEN: 'taken',
  INVOICE: 'invoice',
};

export default {
  mixins: [formMixin],
  components: {
    TableRow,
    WaitingListModal,
  },
  data() {
    return {
      status,
      hoursFilter,
      deleteMultipleAppointmentsModal: {
        selectAll: false,
        appointments: [],
        show: false,
      },
      appointment: {
        show: true,
      },
      filter: this.$store.state.appointment.filter,
      waitingListModal: {
        show: false,
      },
      modalInsuranceInfo: {
        show: false,
        scheduleName: '',
        insurances: [],
      },
    };
  },
  watch: {
    'filter.search': function setHourType() {
      this.filter.hours = this.filter.search ? hoursFilter.TAKEN : hoursFilter.FREE;
    },
  },
  computed: {
    ...mapState({
      appointments: state => state.appointment.items,
      loading: state => state.appointment.loading,
      user: ({ auth }) => auth.user,
    }),
    ...mapGetters({
      hasClipboardData: 'hasClipboardData',
    }),
    isArrivalOrder() {
      return this.appointments
        .filter(item => item.status === 'available')
        .some(item => item.isArrivalOrder);
    },
    arrivalOrderRange() {
      const found = this.appointments
        .find(item => item.status === 'available' && item.isArrivalOrder);

      return {
        start: found.startDate.slice(-5),
        end: found.endDate.slice(-5),
      };
    },
    filteredAppointments() {
      let items = this.appointments.filter(item => (
        !(item.isArrivalOrder && item.status === 'available')
      ));

      if (this.filter.hours === hoursFilter.FREE) {
        items = this.appointments
          .filter(item => item.status === 'available');
      } else if (this.filter.hours === hoursFilter.TAKEN) {
        items = this.appointments
          .filter(item => item.patient && item.patient.name);
      } else if (this.filter.hours === hoursFilter.INVOICE) {
        items = this.appointments
          .filter(item => !item.account && item.patient);
      }

      const params = this.parseFilterSearchParams();

      if (params.value) {
        if (params.isHour) {
          items = items.filter((item) => {
            const hour = Number((
              item.startDate
                .substring(11, 16)
                .replace(':', '')
            ));
            switch (params.operator) {
              case '>': return hour >= params.value;
              case '<': return hour <= params.value;
              case '=': return hour === params.value;
              default: return true;
            }
          });
        } else {
          const results = fuzzy.filter(params.value, items, {
            extract: (el) => {
              if (el.patient && el.patient.name) {
                return this.removeAccentuation(el.patient.name);
              }
              return '';
            },
          });

          items = results.map(({ original }) => original);
        }
      }

      const fakeDate = '3000-01-01T00:00';
      return items.sort((a, b) => {
        if (a.startDate > b.startDate) return 1;
        if (a.startDate < b.startDate) return -1;

        if (!['waiting', 'scheduled'].includes(a.status)) return 1;
        if (!['waiting', 'scheduled'].includes(b.status)) return -1;

        if ((a.arrivedAt || fakeDate) > (b.arrivedAt || fakeDate)) return 1;
        if ((a.arrivedAt || fakeDate) < (b.arrivedAt || fakeDate)) return -1;
        return 0;
      });
    },
    eventsChannel() {
      return `${this.user.branch.id}-appts`;
    },
  },
  mounted() {
    this.filter.hours = hoursFilter.FREE;
    if ('s' in this.$route.query && this.$route.query.s) {
      this.filter.search = this.$route.query.s;
    }
    this.startEvents();
  },
  beforeDestroy() {
    this.stopEvents();
  },
  methods: {
    ...mapActions({
      loadCalendar: 'loadCalendar',
      upsert: 'upsert',
    }),
    ...mapMutations({
      remove: 'Appointment.REMOVE',
      showSlotModal: 'Appointment.SHOW_SLOT_MODAL',
      setInUseBy: 'Appointment.SET_APPOINTMENT_IN_USE_BY',
    }),
    eventListener({ name, data }) {
      if (name === 'delete') {
        this.remove(data);
      } else if (name === 'in-use') {
        this.setInUseBy(data);
      } else if (this.filter.date === data.startDate.substring(0, 10)) {
        if (this.appointments.length > 0) {
          this.upsert(data);
        } else {
          this.loadCalendar();
        }
      }
    },
    startEvents() {
      const { realtime } = AblyService.instance(this.user.id);
      const channel = realtime.channels.get(this.eventsChannel);
      channel.subscribe(this.eventListener);
    },
    stopEvents() {
      const { realtime } = AblyService.instance(this.user.id);
      const channel = realtime.channels.get(this.eventsChannel);
      channel.unsubscribe(this.eventListener);
    },
    openWaitingList() {
      this.waitingListModal.show = true;
    },
    openSlotModal() {
      if (this.hasClipboardData) {
        this.showSlotModal();
      }
    },
    formatFilterSearch() {
      const params = this.parseFilterSearchParams();

      if (params.isHour) {
        this.filter.search = params.operator + StringMask
          .apply(params.value.toString(), '90:00');
      }
    },
    parseFilterSearchParams() {
      const params = {
        value: this.filter.search,
        isHour: false,
        operator: null,
      };

      if (/^[><=]=?/.test(params.value)) {
        const match = params.value.match(/^([><=])(.+)/);
        if (match && match.length > 2) {
          params.isHour = true;
          // eslint-disable-next-line prefer-destructuring
          params.operator = match[1];
          params.value = Number(match[2].replace(/\D/g, ''));

          if (params.value <= 24) {
            params.value *= 100;
          } else if (params.value < 100) {
            params.value *= 10;
          }
        } else {
          params.value = '';
        }
      } else {
        params.value = this.removeAccentuation(params.value);
      }
      return params;
    },
    deleteMultipleAppointments(appointments) {
      this.deleteMultipleAppointmentsModal.appointments = appointments;
      this.deleteMultipleAppointmentsModal.show = true;
    },
    openModalInsuranceList(data) {
      this.modalInsuranceInfo.insurances = data.insurances;
      this.modalInsuranceInfo.scheduleName = data.scheduleName;
      this.modalInsuranceInfo.show = true;
    },
    formatDateTime(datetime) {
      if (datetime) return moment(datetime).format('DD/MM/YYYY HH:mm');
      return '';
    },
    closeDeleteMultipleAppointmentsModal() {
      this.deleteMultipleAppointmentsModal.appointments = [];
      this.deleteMultipleAppointmentsModal.show = false;
    },
    selectAllAppointments() {
      this.deleteMultipleAppointmentsModal.appointments.forEach((item) => {
        if (item.hasAccount === false) {
          item.checked = !this.deleteMultipleAppointmentsModal.selectAll;
        }
      });
    },
    deleteAppointments() {
      const appointments = this.deleteMultipleAppointmentsModal.appointments
        .filter(item => item.checked && item.hasAccount === false);

      if (appointments.length === 0) {
        this.$toast.show('Selecione ao menos um agendamento!', { type: 'error' });
        return;
      }

      const appointmentIds = [];
      appointments.forEach((item) => {
        appointmentIds.push(item.id);
      });

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir os agendamentos selecionados?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$http
                .delete(`/multiple-appointments/${appointmentIds}`)
                .catch(this.$toast.error)
                .finally(() => {
                  this.deleting = false;
                });
              close();
            },
          },
        ],
      });

      this.closeDeleteMultipleAppointmentsModal();
    },
    getAppointmentType(type) {
      return types.getName(type);
    },
    checkDeleteAppointmentItem() {
      const items = this.deleteMultipleAppointmentsModal.appointments
        .filter(item => item.checked);
      this.deleteMultipleAppointmentsModal
        .selectAll = items.length === this.deleteMultipleAppointmentsModal.appointments.length;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
.appointments-content-page {
  .page-toolbar {
    position: sticky;
    background-color: $gray-color-light;
    top: 0;
    @media screen and (max-width: $size-sm) {
    }
  }
  .page-subtoolbar {
    padding: $layout-spacing;
    background-color: #eee;
    position: sticky;
    top: 0;
    overflow: clip visible;
    z-index: $zindex-0;
    @media screen and (max-width: $size-sm) {
    }
  }
  .arrival-order-info {
    align-items: center;
    border: $border-width solid $border-color;
    color: $info-color;
    display: flex;
    padding: $layout-spacing;
    svg {
      font-size: .85rem;
      margin-right: $layout-spacing;
    }
    & + table {
      td {
        border-top: 0;
      }
    }
  }
  table {
    background-color: #fff;
    table-layout: fixed;
    tr {
      td {
        border: .05rem solid $border-color;
      }
    }
  }
  .empty-appointment {
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin: 2rem;
    p {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
