<template>
  <div class="expense-modal">
    <form autocomplete="off" @submit.prevent.stop="addExpense">
      <div class="columns mb-1 mt-2">
        <div class="column col-3 form-group">
          <select class="form-select" v-model="filterBySchedule">
            <option :value="true">Da agenda</option>
            <option :value="false">Da tabela do convênio</option>
          </select>
        </div>
        <div
          class="column col-9 form-group"
          :class="!canSearch ? 'tooltip' : ''"
          :data-tooltip="!canSearch ? 'Convênio e profissional devem estar selecionados' : ''"
        >
          <dx-autocomplete
            v-model="expense"
            :source="findExpense"
            label="name"
            track-by="id"
            :disabled="!canSearch"
            :debounce="800"
            @select="addAppointmentExpense"
            placeholder="Informe o código ou nome da consulta ou outros..."
            input-id="expense-name"
          >
            <template v-slot="{ item }">
              <a>
                <fa-icon :icon="['fal', 'file-medical-alt']" class="text-primary"/>
                <span class="ml-1">
                  {{ item.code | tuss }} - {{ item.expense.name }}
                </span>
              </a>
            </template>
            <template v-slot:action>
              <button
                @click="openExpenseModal"
                class="btn btn-action input-group-btn btn-icon btn-neutral"
                :class="{loading: expenseModal.loading}"
                tabindex="-1"
                :disabled="!canSearch || expenseModal.loading"
              ><fa-icon :icon="['fal', 'search']" /></button>
            </template>
          </dx-autocomplete>
        </div>
      </div>
    </form>

    <template v-if="groupedAccounts.length > 0">
      <div v-for="(account) in groupedAccounts" :key="account.key">
        <div class="account">
          <span class="ml-2">Procedimentos agendados</span>
        </div>
        <div style="margin-left: 1rem; margin-bottom: .4rem">
          <table class="table expense-modal-table"
                 :class="account.id ? 'account-finished' : 'table-hover'">
            <tbody>
            <tr v-for="(expense) in account.expenses" :key="expense.key">
              <td>{{ expense.code | tuss }} - {{ expense.name }}</td>
              <td class="text-right" width="60px">
                <button class="btn btn-sm btn-gray btn-action btn-icon tooltip"
                        data-tooltip="Preparo"
                        @click="getPreparation(expense)"
                        v-if="expense.code != '10101012'">
                  <fa-icon :icon="['fal', 'info-circle']"></fa-icon>
                </button>
              </td>
              <td class="text-center" width="120px">
                <span v-if="account.id">x{{expense.quantity}}</span>
                <span v-else class="tooltip" data-tooltip="Quantidade">
                  <st-input-number-step
                    v-model="expense.quantity"
                    :min-value="1"
                    :max-value="20"
                  />
                </span>
              </td>
              <td class="text-right" width="80px">
                <span class="tooltip" data-tooltip="valor unitário">
                  {{ expense.value.particular | currency }}
                </span>
              </td>
              <td class="text-right" width="80px">
                <span class="tooltip" data-tooltip="Total">
                  {{ ((expense.factor ? expense.factor : 1)
                  * expense.value.particular * expense.quantity) | currency }}
                </span>
              </td>
              <td class="text-right" width="60px">
                <button class="btn btn-sm btn-error btn-action btn-icon tooltip"
                        data-tooltip="Excluir"
                        @click="deleteAppointmentExpense(expense)"
                        v-if="!expense.accountId">
                  <fa-icon :icon="['fal', 'times']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
          <dx-modal title="Informações de preparo"
                    v-model="preparationModal.show" size="lg"
                    v-if="preparationModal.show"
                    id="modal-preparation">
            <div class="preparation-content" v-html="preparation" />
            <template slot="footer">
              <button class="btn" @click="preparationModal.show = false">Sair</button>
            </template>
          </dx-modal>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="empty">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Procedimentos</p>
        <p class="empty-subtitle">
          Nenhum procedimento encontrado. Informe o código ou nome no campo acima.
        </p>
      </div>
    </template>

    <dx-modal
      title="Lista de procedimentos do profissional"
      v-model="expenseModal.show"
      size="lg"
      v-if="expenseModal.show"
      id="modal-expenses-appointment"
    >
      <div class="empty mt-2"
           v-if="expenseModal.data.items.length === 0 && !expenseModal.loading">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
        </div>
        <p class="empty-title h6">Procedimentos do profissional</p>
        <p class="empty-subtitle">
          Nenhum procedimento encontrado para este profissional. Verifique as configurações.
        </p>
      </div>
      <div class="loading loading-lg mt-2" v-else-if="expenseModal.loading" />
      <table class="table table-hover expense-modal-table" v-else>
        <thead>
        <tr>
          <th>Código</th>
          <th>Nome</th>
          <th class="text-right">Valor Paciente</th>
        </tr>
        </thead>
        <tbody>
        <tr class="c-hand" v-for="(item, i) in expenseModal.data.items" :key="i"
            @click="selectExpense(item)">
          <td>
            <span v-if="item.code">{{ item.code | tuss }}</span>
            <span v-else>-</span>
          </td>
          <td>{{ item.expense.name }}</td>
          <td class="text-right">
            {{ item.definition ? item.definition.particular : 0 | currency }}
          </td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="expenseModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import moment from 'moment';

export default {
  props: {
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      source: 'appointments',
      editing: false,
      printing: false,
      deleting: false,
      loading: false,
      expense: null,
      referralTypes: [
        { code: 'consultation', name: 'Guia de Consulta' },
        { code: 'sadt', name: 'Guia de SP/SADT' },
      ],
      referralType: null,
      filterBySchedule: true,
      expenseModal: {
        loading: false,
        show: false,
        data: {
          items: [],
          hasMore: false,
          limit: 0,
          offset: 0,
        },
      },
      preparationModal: {
        show: false,
        printing: false,
        expenseId: '',
        preparation: '',
      },
      account: {
        type: '',
        data: {},
        show: false,
      },
      form: {},
    };
  },
  created() {
    this.form = this.$store.state.appointment.form;
  },
  computed: {
    groupedAccounts() {
      return this.form.expenses
        .reduce((result, expense) => {
          let found = result.find(({ id }) => expense.accountId === id);
          if (!found) {
            found = {
              id: expense.accountId,
              key: expense.accountId || 'no-account',
              expenses: [],
            };
            result.push(found);
          }
          found.expenses.push(expense);
          return result;
        }, [])
        .sort((a, b) => {
          if (!a.id) return -1;
          if (!b.id) return 1;
          return 0;
        });
    },
    isSelected() {
      return this.form.expenses
        .some(item => item.accountId === null && item.selected);
    },
    isWaiting() {
      return this.deleting || this.loading || this.saving;
    },
    canSearch() {
      return this.form.insurance.plan.id && this.form.professional.id;
    },
    preparation() {
      if (!this.preparationModal.preparation) return '';

      return this.preparationModal.preparation
        .replace(/(<figure([^>]+)>)/ig, '')
        .replace(/<\/figure>/ig, '')
        .replace(/<table>/ig, '<table class="table">');
    },
  },
  methods: {
    ...mapMutations({
      showAccountModal: 'Appointment.SHOW_ACCOUNT_MODAL',
      addExpense: 'Appointment.ADD_EXPENSE',
      deleteExpense: 'Appointment.DELETE_EXPENSE',
      setAccount: 'Appointment.SET_ACCOUNT',
    }),
    total(account) {
      return account.expenses
        .reduce((a, b) => a + Number(b.value.particular
          * b.quantity * (b.factor ? b.factor : 1)), 0);
    },
    findExpense(search) {
      const params = {
        search,
        insuranceId: this.form.insurance.id,
        planId: this.form.insurance.plan.id,
        limit: 30,
        offset: 0,
      };

      if (this.filterBySchedule) {
        params.scheduleId = this.form.schedule.id;
      }

      return this.$http
        .get(`/professionals/${this.form.professional.id}/expense-prices`, { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{
              id: null,
              name: 'Procedimento não encontrado neste convênio',
              quantity: 0,
              factor: 0,
              value: {
                insurance: 0,
                particular: 0,
                total: 0,
              },
            }];
          }
          return data.items;
        });
    },
    addAppointmentExpense(expense) {
      if (!expense || expense.expense.id === null) {
        return;
      }

      const found = this.form.expenses
        .find(item => (item.id === expense.id && !item.accountId));
      if (found) {
        found.quantity += 1;
      } else {
        this.addExpense({
          id: expense.expense.id,
          selected: true,
          code: expense.code,
          name: expense.expense.name,
          specialty: expense.specialty || null,
          quantity: 1,
          factor: 1,
          value: {
            insurance: expense.definition.insuranceValue,
            particular: expense.definition.particularValue,
          },
          accountId: null,
        });
        if (expense.definition.insuranceValue + expense.definition.particularValue === 0) {
          this.$toast.show('Atenção! Procedimento ainda não precificado.', { type: 'error' });
        }
        this.$emit('validateScheduleInfo');
      }

      if (expense.hasPreparation) {
        this.getPreparation(expense.expense.id);
      }

      this.expense = null;
    },
    print() {
      this.preparationModal.printing = true;
      return this.$file
        .print(`/expenses/${this.preparationModal.expenseId}/print-preparation`)
        .catch(() => {})
        .finally(() => {
          this.preparationModal.printing = false;
        });
    },
    openExpenseModal() {
      this.loadExpenseModalItems();
      this.expenseModal.show = true;
    },
    async loadExpenseModalItems() {
      this.expenseModal.loading = true;
      this.expenseModal.items = [];

      const params = {
        insuranceId: this.form.insurance.id,
        planId: this.form.insurance.plan.id,
        limit: 30,
        offset: 0,
      };

      if (this.filterBySchedule) {
        params.scheduleId = this.form.schedule.id;
      }

      this.$http.get(`/professionals/${this.form.professional.id}/expense-prices`, { params })
        .then(({ data }) => {
          this.expenseModal.data.hasMore = data.hasMore;
          this.expenseModal.data.items = data.items;
        })
        .catch(() => {})
        .finally(() => {
          this.expenseModal.loading = false;
        });
    },
    selectExpense(item) {
      this.addAppointmentExpense(item);
      this.expenseModal.show = false;
    },
    deleteAppointmentExpense(expense) {
      this.deleteExpense(expense);
    },
    async openAccount() {
      const tiss = this.referralType;

      let recordLength = 0;
      if (this.form.insurance.record) {
        recordLength = this.form.insurance.record.length;
      }
      if (this.form.insurance.plan.settings.record.required
        && this.form.insurance.plan.settings.record.requiredValidity) {
        if (recordLength === 0 && !this.form.insurance.validity) {
          this.$toast.show(
            'Informe o número e a validade da carteirinha do paciente na aba Agendamento!',
            { type: 'error' },
          );
          return;
        }
      }
      if (this.form.insurance.plan.settings.record.required) {
        if (recordLength === 0) {
          this.$toast.show('Informe a carteirinha do paciente na aba Agendamento!', { type: 'error' });
          return;
        }
      }
      if (this.form.insurance.plan.settings.record.requiredValidity) {
        if (!this.form.insurance.validity) {
          this.$toast.show('Informe a validade da carteirinha do paciente na aba Agendamento!', { type: 'error' });
          return;
        }
      }
      if (this.form.insurance.plan.settings.record.digits.enabled) {
        if (recordLength < this.form.insurance.plan.settings.record.digits.min) {
          this.$toast.show(`A carteirinha deve ter no mínimo ${this.form.insurance.plan.settings.record.digits.min} dígito(s)!`, { type: 'error' });
          return;
        }
        if (recordLength > this.form.insurance.plan.settings.record.digits.max) {
          this.$toast.show(`A carteirinha deve ter no máximo ${this.form.insurance.plan.settings.record.digits.max} dígito(s)!`, { type: 'error' });
          return;
        }
      }

      this.loading = true;

      const account = {
        id: '',
        appointmentId: this.form.id,
        professionalId: this.form.professional.id,
        specialtyCode: this.form.professional.specialty || null,
        key: this.form.key,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        patient: {
          id: this.form.patient.id,
          name: this.form.patient.name,
          birthDate: this.form.patient.birthDate,
          gender: this.form.patient.gender,
          cns: this.form.patient.cns ? this.form.patient.cns : null,
          insurance: {
            id: this.form.insurance.id,
            name: this.form.insurance.name || this.form.patient.insurance.name,
            plan: this.form.insurance.plan,
            insured: {
              record: this.form.insurance.record,
              validity: this.form.insurance.validity,
            },
          },
        },
        expenses: this.form.expenses
          .filter(({ selected }) => selected)
          .map((expense) => {
            expense.value.discount = 0;
            expense.value.interest = 0;
            expense.value.tax = 0;
            expense.value.particular = expense.value.particular || 0;
            expense.value.insurance = expense.value.insurance || 0;

            const item = {
              id: expense.id,
              startDate: this.form.startDate,
              endDate: this.form.endDate,
              execution: {
                date: moment(this.form.startDate).format('YYYY-MM-DD'),
                time: moment(this.form.startDate).format('HH:mm'),
                duration: moment.duration(moment(this.form.endDate)
                  .diff(moment(this.form.startDate))).asMinutes(),
              },
              accountId: '',
              code: expense.code,
              name: expense.name,
              rules: {
                requireAuthorization: false,
                requireClinicalIndication: false,
                requireWayTechnique: false,
              },
              quantity: expense.quantity,
              factor: expense.factor ? expense.factor : 1,
              way: null,
              technique: null,
              value: expense.value,
              professional: {},
            };

            if (!expense.professional) {
              item.professional = {
                id: this.form.professional.id,
                name: this.form.professional.name,
                council: this.form.professional.council,
                specialty: {
                  code: this.form.professional.specialty,
                },
                degreeParticipation: 'clinical',
              };
            }
            return item;
          }),
      };

      if (tiss) {
        const params = {
          expenseIds: account.expenses.map(({ id }) => id).join(','),
        };
        await this.$http.get(`/expense-insurances/${this.form.insurance.plan.id}/rules`, { params })
          .then(({ data }) => {
            if (data.items) {
              if (data.items.length > 0) {
                data.items.forEach((item) => {
                  account.expenses.forEach((expense) => {
                    if (item.id === expense.id) {
                      if (item.rules) {
                        expense.rules = item.rules;
                      }
                    }
                  });
                });
              }
            }
          });

        if (this.form.insurance.plan.settings.consultationInSadt) {
          this.account.type = 'sadt';
        } else {
          this.account.type = tiss;
        }
      } else {
        this.account.type = this.form.insurance.type;
      }
      this.account.data = account;
      this.account.show = true;
      this.loading = false;
    },
    async printRequest(accountId) {
      this.printing = true;
      return this.$file
        .print(`/billing-accounts/${accountId}/printRequest`)
        .catch(() => {
          this.$toast.show('Erro na impressão!', { type: 'error' });
        })
        .then(() => {
          this.printing = false;
        });
    },
    async printAccount(accountId) {
      this.printing = true;
      return this.$file
        .print(`/billing-accounts/${accountId}/print`)
        .then(() => {
          this.printing = false;
        });
    },
    async editAccountModal(accountId) {
      this.editing = true;
      let account;
      await this.$http.get(`/billing-accounts/${accountId}`)
        .then(({ data }) => {
          const professional = data.expenses.length > 0
            ? data.expenses[0].professional
            : null;
          if (data.invoice && data.invoice.id) {
            data.invoiceId = data.invoice.id;
          }
          data.professionalId = professional.id;
          data.specialtyCode = professional.specialty
            ? professional.specialty.code
            : null;
          data.key = this.form.key;
          data.expenses.forEach((expense) => {
            expense.accountId = accountId;
            expense.execution = {
              date: moment(expense.startDate).format('YYYY-MM-DD'),
              time: moment(expense.startDate).format('HH:mm'),
              duration: moment.duration(moment(expense.endDate)
                .diff(moment(expense.startDate))).asMinutes(),
            };
            expense.rules = {
              requireAuthorization: false,
              requireClinicalIndication: false,
              requireWayTechnique: false,
            };
          });
          account = data;
        });

      if (this.form.insurance.plan.settings.tiss.enabled) {
        const params = {
          expenseIds: account.expenses.map(({ id }) => id).join(','),
        };
        await this.$http.get(`/expense-insurances/${this.form.insurance.plan.id}/rules`, { params })
          .then(({ data }) => {
            if (data.items) {
              if (data.items.length > 0) {
                data.items.forEach((item) => {
                  account.expenses.forEach((expense) => {
                    if (item.id === expense.id) {
                      if (item.rules) {
                        expense.rules = item.rules;
                      }
                    }
                  });
                });
              }
            }
          });
        if (this.form.insurance.plan.settings.consultationInSadt) {
          this.account.type = 'sadt';
        } else {
          this.account.type = account.referral.type;
        }
      } else {
        this.account.type = account.patient.insurance.type;
      }
      this.account.data = account;
      this.account.show = true;
      this.editing = false;
    },
    removeAccountAppointment(id) {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente cancelar esta conta e estornar os procedimentos?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.$http
                .delete(`/billing-accounts/${id}`)
                .then(() => {
                  this.$store.state.appointment.form.status = 'scheduled';
                  this.form.expenses.forEach((expense) => {
                    if (id === expense.accountId) {
                      expense.accountId = null;
                      expense.value.discount = 0;
                      expense.value.interest = 0;
                      expense.value.tax = 0;
                      expense.value.rate = 0;
                      expense.selected = false;
                    }
                  });
                  this.setAccount({
                    key: this.form.key,
                    expenses: this.form.expenses,
                  });
                  close();
                })
                .catch((e) => {
                  if (e.response
                    && e.response.data
                    && e.response.data.message) {
                    this.$toast.show(e.response.data.message, { type: 'error', timeout: 5000 });
                    close();
                  }
                })
                .then(() => {
                  this.deleting = false;
                });
            },
          },
        ],
      });
    },
    getPreparation(expense) {
      const expenseId = expense.id;
      this.$http.get(`/expenses/${expenseId}`)
        .then(({ data }) => {
          if (data.preparation) {
            this.preparationModal.expenseId = expenseId;
            this.preparationModal.preparation = data.preparation;
            this.preparationModal.show = true;
          } else {
            this.$toast.show('Procedimento sem preparo cadastrado', { type: 'error' });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
.expense-modal {
  .account {
    background-color: #eee;
    padding: .4rem;
    border: 1px solid $border-color;
  }
  .account-finished {
    background-color: lighten($gray-color-light, 4%);
  }
  .expense-modal-table {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    &.table-hover {
      tr:hover {
        background-color: lighten($border-color, 6%);
      }
    }
  }
  #div-register-service li a:hover {
    background-color: $gray-color-light;
    color: black;
  }
}

#modal-preparation {
  .preparation-content {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    padding: $layout-spacing-lg;
    p {
      margin-bottom: $layout-spacing;
    }
  }
}
</style>
