<template>
  <div class="appointments-page main-page">
    <portal to="page-name">Agenda</portal>

    <div class="main-page-side">
      <side-page/>
    </div>
    <div class="main-page-content">
      <content-page/>
    </div>
    <dx-modal :title="appointmentModalTitle"
              :value="showAppointmentModal" size="lg"
              @input="hideAppointmentModal" id="modal-appointment">
      <st-tabs ref="appointment-tabs" @changed="loadData">
        <st-tab id="main" name="Agendamento">
          <appointment-modal
            ref="appointmentModal"
            @switch-tab="switchTab"
          />
        </st-tab>
        <st-tab id="expenses" name="Procedimentos" v-if="!isBlockedByAnotherUser">
          <expenses-modal
            :saving="saving"
            ref="expensesModal"
            @validateScheduleInfo="validateScheduleInfo"
          />
        </st-tab>
        <st-tab id="attachments" name="Anexos" v-if="!isBlockedByAnotherUser">
          <template v-if="form.id && form.patient.id && showFiles">
            <st-entity-file-manager
              :entity-id="form.patient.id"
              :origin-id="form.id"
              origin-type="appointment"
              :key="form.id" />
          </template>
          <div class="empty mt-2" v-else>
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'lock']" size="3x"></fa-icon>
            </div>
            <p class="empty-title h5">Anexo de documentos</p>
            <div v-if="form.patient.id">
              <p class="empty-subtitle">
                Para anexar documetos você precisa salvar este agendamento.
              </p>
              <button class="btn btn-primary mr-2"
                      :class="{ loading: saving }"
                      :disabled="saving"
                      @click="save">Salvar e manter aberto</button>
            </div>
            <p class="empty-subtitle" v-else>
              Para anexar documetos você precisa selecionar um paciente e salvar este agendamento.
            </p>
          </div>
        </st-tab>
      </st-tabs>
      <template v-slot:footer>
        <button
          class="btn btn-primary mr-2"
          :class="{ loading: savingAction === 'main' }"
          :disabled="saving"
          @click="saveAndClose()"
          v-if="!isBlockedByAnotherUser"
        >Salvar agendamento</button>
        <button class="btn" @click="hideAppointmentModal">Sair</button>
      </template>
    </dx-modal>

    <search-modal :show="showSearchAppointmentModal"
                 v-if="showSearchAppointmentModal"
                 @close="hideSearchAppointmentModal" />

    <replicate-modal
      v-if="replicate.show"
      :show="replicate.show"
      :id="form.id"
      :patient="form.patient"
      @close="hideReplicateModal"
    />

    <print-modal :show="showPrintAppointmentModal"
                 v-if="showPrintAppointmentModal"
                 @close="hidePrintAppointmentModal" />

    <slot-modal :show="showSlotModal"
                 v-if="showSlotModal"
                 @close="hideSlotModal" />

    <confirmation-modal :show="confirmation.show"
                  v-if="confirmation.show"
                  :data="confirmation.data"
                  @close="confirmation.show = false" />

    <dx-modal title="Agendamento por paciente"
              id="modal-patient-appointment" size="lg"
              v-if="showPatientAppointmentModal"
              :value="showPatientAppointmentModal"
              @input="hidePatientAppointmentModal">
      <div class="filter-group">
        <div class="columns">
          <div class="column col-6 form-group"
               :class="{'has-error': $v.modalPatientAppointment.patientId.$error}">
            <label class="form-label">Paciente</label>
            <dx-autocomplete
              v-model="modalPatientAppointment.patient"
              :source="findPatient"
              label="name"
              track-by="id"
              @input="setPatient"
              placeholder="Nome do paciente, data de nascimento ou CPF"
              input-id="appointment-patient"
              :debounce="800"
              :readonly="!!modalPatientAppointment.patient"
              ref="patientSearch"
            >
              <template slot="action">
                <button
                  @click="unsetPatient"
                  class="btn btn-action input-group-btn btn-icon"
                  :class="modalPatientAppointment.patient && modalPatientAppointment.patient.name
                 ? 'btn-gray' : 'btn-neutral'"
                  tabindex="-1">
                  <fa-icon :icon="['fal', modalPatientAppointment.patient
                 && modalPatientAppointment.patient.name ? 'times' : 'search']"></fa-icon>
                </button>
              </template>
              <template v-slot="{ item }">
                <a v-if="item.id">{{ item.name }} ({{ item.birthDate | date }})</a>
                <a v-else>{{ item.name }} (não cadastrado)</a>
              </template>
            </dx-autocomplete>
          </div>
          <div class="column col-6 form-group"
               :class="{'has-error': $v.modalPatientAppointment.insuranceKey.$error}">
            <label class="form-label">Convênio</label>
            <select class="form-select" v-model="modalPatientAppointment.insuranceKey">
              <option value="">[Selecione o convênio]</option>
              <option v-for="(item, i) in modalPatientAppointment.insurances"
                      :value="item.key" :key="i">{{ item.name}}</option>
            </select>
          </div>
          <div class="column col-6 form-group"
               :class="{'has-error': $v.modalPatientAppointment.expenseId.$error}">
            <label class="form-label">Procedimento</label>
            <dx-autocomplete
              v-model="modalPatientAppointment.expense"
              :source="findExpense"
              label="name"
              track-by="id"
              @input="setExpense"
              placeholder="Nome ou código do procedimento"
              input-id="appointment-expense"
              :debounce="800"
              :readonly="!!modalPatientAppointment.expense"
              ref="patientSearch"
            >
              <template slot="action">
                <button
                  @click="unsetExpense"
                  class="btn btn-action input-group-btn btn-icon"
                  :class="modalPatientAppointment.expense && modalPatientAppointment.expense.name
                 ? 'btn-gray' : 'btn-neutral'"
                  tabindex="-1">
                  <fa-icon :icon="['fal', modalPatientAppointment.expense
                 && modalPatientAppointment.expense.name ? 'times' : 'search']"></fa-icon>
                </button>
              </template>
            </dx-autocomplete>
          </div>
          <div class="column col-6 form-group">
            <label class="form-label">Agenda (opcional)</label>
            <select class="form-select" v-model="modalPatientAppointment.scheduleId">
              <option value="">[Selecione a agenda]</option>
              <option v-for="(item, i) in modalPatientAppointment.schedules"
                      :value="item.id" :key="i">{{ item.name}}</option>
            </select>
          </div>
        </div>
        <div class="has-error-message" v-if="modalPatientAppointment.info">
          <strong>
            <fa-icon class="mr-1" :icon="['fal', 'clock']" />
            <span>{{ modalPatientAppointment.info.message }}</span>
          </strong>
        </div>
        <button class="btn btn-icon btn-icon-left btn-primary mt-2 mb-1"
                :class="{ loading: modalPatientAppointment.loading }"
                :disabled="modalPatientAppointment.loading"
                @click="loadPatientAppointments">
          <fa-icon :icon="['fal', 'search']"/>Pesquisar horários disponíveis
        </button>
      </div>
      <div class="empty mt-2" v-if="modalPatientAppointment.results.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h6">Horários disponíveis</p>
        <p class="empty-subtitle">
          Nenhum horário encontrado. Verifique os filtros e tente novamente!
        </p>
      </div>
      <template v-else>
        <div v-for="(appointment, i) in modalPatientAppointment.results" :key="i">
          <div class="appointment-date">
            {{ appointment.startDate | date('dddd[, ]DD[ de ]MMMM[ de ]YYYY') }}
          </div>
          <div class="appointment-hour">
            <div class="hour" v-for="(item, n) in appointment.hours" :key="n"
                 @click="openProfessionalModal(appointment.startDate, item)">
              {{ item.startHour }}
            </div>
          </div>
        </div>
      </template>
      <template slot="footer">
        <button class="btn btn-gray"
                @click="hidePatientAppointmentModal">Sair</button>
      </template>
    </dx-modal>

    <dx-modal title="Escolha o profissional"
              id="modal-professional-appointment"
              v-if="modalProfessional.show"
              v-model="modalProfessional.show">
      <span>
        Agendar para o dia:
        <strong class="text-secondary">{{ modalProfessional.date | date }}</strong> às
        <strong class="text-secondary">{{ modalProfessional.startHour }}</strong>
      </span>

      <div class="divider" />

      <div class="loading loading-lg mt-2" v-if="modalProfessional.saving" />
      <table class="table table-striped table-hover" v-else>
        <thead>
        <tr>
          <th>Agenda</th>
        </tr>
        </thead>
        <tbody>
        <tr class="c-hand" @click="savePatientAppointment(item)"
            v-for="(item, i) in modalProfessional.schedules" :key="i">
          <td class="text-bold text-primary">
            {{ item.name }}
          </td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn btn-gray"
                @click="modalProfessional.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import moment from 'moment';
import { required } from 'vuelidate/src/validators';
import { mergeFrom } from 'src/helpers/object';
import AppointmentModal from './modals/Appointment.vue';
import ConfirmationModal from './modals/Confirmation.vue';
import ContentPage from './ContentPage.vue';
import ExpensesModal from './modals/Expense.vue';
import PrintModal from './modals/Print.vue';
import ReplicateModal from './modals/Replicate.vue';
import SearchModal from './modals/Search.vue';
import SidePage from './SidePage.vue';
import SlotModal from './modals/Slot.vue';

export default {
  components: {
    AppointmentModal,
    ConfirmationModal,
    ContentPage,
    ExpensesModal,
    PrintModal,
    ReplicateModal,
    SearchModal,
    SidePage,
    SlotModal,
  },
  data() {
    return {
      path: '/appointments',
      loading: false,
      printing: false,
      saving: false,
      savingAction: null,
      data: {
        items: [],
        total: 1,
        limit: 30,
        offset: 0,
      },
      document: {
        loading: false,
        show: false,
      },
      replicate: {
        loading: false,
        show: false,
      },
      confirmation: {
        show: false,
        data: {
          isExam: false,
          appointmentId: '',
          date: '',
          status: '',
          professionalName: '',
          patientName: '',
          insuranceName: '',
          expenses: [],
        },
      },
      form: this.$store.state.appointment.form,
      needToSave: !this.$store.state.appointment.form.id,
      appointmentWatchers: [],
      modalPatientAppointment: {
        info: null,
        patient: null,
        expense: null,
        loading: false,
        patientId: '',
        expenseId: '',
        insuranceKey: '',
        scheduleId: '',
        insurances: [],
        schedules: [],
        results: [],
      },
      modalProfessional: {
        show: false,
        saving: false,
        date: null,
        startHour: null,
        endHour: null,
        schedules: [],
      },
      currentTab: 'main',
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      showAppointmentModal: state => state.appointment.show,
      showSlotModal: state => state.appointment.slotShow,
      showPrintAppointmentModal: state => state.appointment.printShow,
      showPatientAppointmentModal: state => state.appointment.patientAppointmentShow,
      showSearchAppointmentModal: state => state.appointment.searchShow,
      showFiles: state => state.appointment.showFiles,
      showHistory: state => state.appointment.showHistory,
    }),
    ...mapGetters({
      appointmentModalTitle: 'appointmentModalTitle',
      getProfessionalById: 'getProfessionalById',
      getInsuranceByPlanId: 'getInsuranceByPlanId',
      hasModule: 'hasModule',
      isBlockedByAnotherUser: 'isBlockedByAnotherUser',
    }),
    branch() {
      return this.user ? this.user.branch : null;
    },
  },
  watch: {
    showAppointmentModal(value) {
      if (value) {
        this.setAppointmentWatchers();
      } else {
        this.unsetAppointmentWatchers();
      }
    },
  },
  mounted() {
    this.loadInsurances();
    this.loadSchedules();
  },
  validations() {
    return {
      modalPatientAppointment: {
        patientId: { required },
        expenseId: { required },
        insuranceKey: { required },
      },
    };
  },
  methods: {
    ...mapActions({
      hideAppointmentModal: 'closeAppointmentModal',
    }),
    ...mapMutations({
      hideSlotModal: 'Appointment.HIDE_SLOT_MODAL',
      hideCommitmentAppointmentModal: 'Appointment.HIDE_COMMITMENT_APPOINTMENT_MODAL',
      hidePrintAppointmentModal: 'Appointment.HIDE_PRINT_APPOINTMENT_MODAL',
      hidePatientAppointmentModal: 'Appointment.HIDE_PATIENT_APPOINTMENT_MODAL',
      hideSearchAppointmentModal: 'Appointment.HIDE_SEARCH_APPOINTMENT_MODAL',
    }),
    setAppointmentWatchers() {
      const setNeedToSave = () => {
        this.needToSave = true;
      };
      this.appointmentWatchers = [
        this.$watch('form.insurance.id', setNeedToSave),
        this.$watch('form.patient.id', setNeedToSave),
        this.$watch('form.expenses', setNeedToSave),
      ];
    },
    unsetAppointmentWatchers() {
      this.appointmentWatchers.forEach((unwatch) => {
        unwatch();
      });
      this.appointmentWatchers = [];
    },
    loadData({ tab }) {
      this.currentTab = tab.id;
      if (tab.id === 'expenses') {
        this.$nextTick(() => {
          document.getElementById('expense-name').focus();
        });
      } else if (tab.id === 'patient') {
        if (!this.form.patient.canSave) {
          this.form.patient.canSave = true;
          if (this.form.patient.id) {
            this.loadPatient(this.form.patient.id);
          }
        }
      } else if (tab.id === 'attachments') {
        this.$store.state.appointment.showFiles = true;
      } else if (tab.id === 'history') {
        this.$store.state.appointment.showHistory = true;
      }
    },
    hideReplicateModal() {
      this.replicate.show = false;
    },
    validateScheduleInfo() {
      this.$refs.appointmentModal.getScheduleInfo();
    },
    validateAppointment() {
      if (!this.$refs.appointmentModal.validate()) {
        this.$refs['appointment-tabs'].selectTab('main');
        return false;
      }
      return true;
    },
    validatePatient() {
      if (!this.form.patient.canSave) {
        return true;
      }
      if (!this.$refs.patientModal.validate()) {
        this.$refs['appointment-tabs'].selectTab('patient');
        return false;
      }
      return true;
    },
    validate() {
      return this.validateAppointment() && this.validatePatient();
    },
    loadInsurances() {
      if (this.modalPatientAppointment.insurances.length > 0) {
        return null;
      }
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.modalPatientAppointment.insurances = data.items.map(item => ({
            key: `${item.id}_${item.plan.id}`,
            name: item.customName,
          }));
        })
        .catch(() => {});
    },
    loadSchedules() {
      const params = {
        limit: 0,
      };

      return this.$http
        .get('/schedules', { params })
        .then(({ data }) => {
          this.modalPatientAppointment.schedules = data.items.map(item => ({
            id: item.id,
            name: item.name,
          }));
        })
        .catch(() => {});
    },
    modalPatientAppointmentClear() {
      this.modalPatientAppointment.info = null;
      this.modalPatientAppointment.patient = null;
      this.modalPatientAppointment.expense = null;
      this.modalPatientAppointment.patientId = '';
      this.modalPatientAppointment.expenseId = '';
      this.modalPatientAppointment.insuranceKey = '';
      this.modalPatientAppointment.professionalId = '';
      this.modalPatientAppointment.results = [];
      this.$v.modalPatientAppointment.$reset();
    },
    findPatient(search) {
      const params = {
        notFilterBranch: true,
        search,
      };

      return this.$http.get('/patients', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    setPatient(patient) {
      if (patient && patient.id) {
        this.modalPatientAppointment.patientId = patient.id;
        this.modalPatientAppointment.insuranceKey = `${
          patient.patient.insurance.id}_${patient.patient.insurance.planId}`;
      }
    },
    unsetPatient() {
      this.modalPatientAppointmentClear();
    },
    findExpense(search) {
      const params = {
        active: true,
        limit: 20,
        search,
      };

      return this.$http.get('/expenses', { params })
        .then(({ data }) => data.items);
    },
    setExpense(expense) {
      if (expense && expense.id) {
        this.modalPatientAppointment.expenseId = expense.id;
      }
    },
    unsetExpense() {
      this.modalPatientAppointment.expense = null;
      this.modalPatientAppointment.expenseId = '';
      this.modalPatientAppointment.info = null;
      this.modalPatientAppointment.results = [];
    },
    loadPatientAppointments() {
      this.$v.modalPatientAppointment.$touch();
      if (this.$v.modalPatientAppointment.$error) {
        return null;
      }

      this.modalPatientAppointment.loading = true;
      this.modalPatientAppointment.info = null;

      const insurance = this.modalPatientAppointment.insuranceKey.split('_');
      const params = {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(30, 'days').format('YYYY-MM-DD'),
        insuranceId: insurance[0],
        insurancePlanId: insurance[1],
        expenseId: this.modalPatientAppointment.expenseId,
      };

      if (this.modalPatientAppointment.scheduleId) {
        params.scheduleId = this.modalPatientAppointment.scheduleId;
      }

      // if (params.insurancePlanId === '60958d021f7a6f1f99378549'
      //   && params.expenseId === '621599c85865360b751b84a9'
      //   && this.modalPatientAppointment.patient.id === '5ed2bcd53bd13d342781ffb7') {
      //   this.modalPatientAppointment.info = {
      //     message: 'Paciente em período de retorno até o dia 12/09/2024.',
      //   };
      // }
      //
      // if (params.insurancePlanId === '5e824f3e907b920e6a403492'
      //   && params.expenseId === '5f7f5f3ebc220a0518cbe4fa'
      //   && this.modalPatientAppointment.patient.id === '5ed2bcd43bd13d342781ffb4') {
      //   this.modalPatientAppointment.info = {
      //     message: 'Paciente em período de retorno até o dia 20/09/2024.',
      //   };
      // }

      return this.$http.get('/calendar/available', { params })
        .then(({ data }) => {
          this.modalPatientAppointment.results = data.items.reduce((result, item) => {
            const date = moment(item.startDate).format('YYYY-MM-DD');
            const startHour = moment(item.startDate).format('HH:mm');
            const endHour = moment(item.endDate).format('HH:mm');

            let found = result.find(({ startDate }) => date === startDate);

            if (!found) {
              found = {
                startDate: date,
                hours: [],
              };
              result.push(found);
            }

            const foundSchedule = data.schedules.find(({ id }) => id === item.scheduleId);
            const professionalIds = item.available.professionals.map(({ id }) => id);
            const professionals = data.professionals
              .filter(({ id }) => id.includes(professionalIds));
            const schedule = {
              id: foundSchedule.id,
              name: foundSchedule.name,
              professionals,
            };

            const foundHour = found.hours.find(x => x.startHour === startHour);
            if (!foundHour) {
              found.hours.push({
                startHour,
                endHour,
                schedules: [schedule],
              });
            } else {
              foundHour.schedules.push(schedule);
            }

            return result;
          }, []);
        }).catch({})
        .finally(() => {
          this.modalPatientAppointment.loading = false;
        });
    },
    openProfessionalModal(date, selected) {
      this.modalProfessional.show = true;
      this.modalProfessional.date = date;
      this.modalProfessional.startHour = selected.startHour;
      this.modalProfessional.endHour = selected.endHour;
      this.modalProfessional.schedules = selected.schedules;
    },
    savePatientAppointment(schedule) {
      if (this.modalProfessional.saving) {
        return null;
      }

      this.modalProfessional.saving = true;

      const professional = schedule.professionals[0];

      const insurance = this.modalPatientAppointment.insuranceKey.split('_');
      const { expense } = this.modalPatientAppointment;
      const { patient } = this.modalPatientAppointment;

      const data = {
        scheduleId: schedule.id,
        startDate: `${this.modalProfessional.date} ${this.modalProfessional.startHour}`,
        endDate: `${this.modalProfessional.date} ${this.modalProfessional.endHour}`,
        professional: {
          id: professional.id,
          name: professional.name,
          specialty: professional.specialties && professional.specialties.length > 0
            ? professional.specialties[0].code
            : null,
        },
        patient: {
          id: patient.id,
          name: patient.name,
          birthDate: patient.birthDate,
          gender: patient.gender,
          phone: patient.phone,
          cellphone: patient.cellphone,
          email: patient.email,
        },
        insurance: {
          id: insurance[0],
          planId: insurance[1],
          record: null,
          validity: null,
        },
        status: 'scheduled',
        expenses: [{
          id: expense.id,
          name: expense.name,
          quantity: 1,
        }],
        origin: 'call_center',
      };

      return this.$http.post('/appointments', data)
        .then(() => {
          this.$toast.success('Agendamento realizado!');
          this.hidePatientAppointmentModal();
          this.modalPatientAppointmentClear();
          this.modalProfessional.show = false;
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro ao salvar. Tente novamente!');
        })
        .finally(() => {
          this.modalProfessional.saving = false;
        });
    },
    async saveAndClose(status = null) {
      this.savingAction = 'main';
      if (status && typeof status === 'string') {
        this.form.status = status;
        this.savingAction = 'waiting';
      }

      try {
        await this.save();
        this.hideAppointmentModal();
        if (this.form.status === 'scheduled') {
          this.openConfirmationModal();
        }
      } catch (e) {
        this.showError(e);
      }

      this.savingAction = null;
    },
    async save(options = {}) {
      if (this.saving) {
        return null;
      }

      options = {
        checkAccount: true,
        ...options,
      };

      if (!this.validate()) {
        throw new Error('Existem campos com informações incorretas');
      }

      this.saving = true;

      if (this.form.status === 'waiting') {
        this.form.arrivedAt = this.form.arrivedAt || moment().format('YYYY-MM-DDTHH:mm');
      } else if (
        !['in_attendance', 'finished', 'report', 'payment', 'screening']
          .includes(this.form.status)
      ) {
        this.form.arrivedAt = null;
      }

      if (
        options.checkAccount
        && ['waiting', 'finished', 'in_attendance', 'screening', 'report']
          .includes(this.form.status)
        && this.user.branch.settings
        && this.user.branch.settings['clinical.blockServiceWithoutAccount']
      ) {
        const hasAccount = this.form.expenses.some(({ accountId }) => accountId);
        if (!hasAccount) {
          this.$refs['appointment-tabs'].selectTab('expenses');
          this.saving = false;
          this.savingAction = null;
          throw new Error('É obrigatório registrar um atendimento');
        }
      }

      const identity = this.form.patient.identity
        ? {
          type: this.form.patient.identity.type || 'cpf',
          value: this.form.patient.identity.value,
        } : null;

      const formData = {
        scheduleId: this.form.scheduleId,
        startDate: this.form.startDate,
        endDate: this.form.endDate || this.form.startDate,
        professional: this.form.professional,
        patient: {
          canSave: this.form.patient.canSave,
          id: this.form.patient.id,
          identity,
          name: this.form.patient.name,
          birthDate: this.form.patient.birthDate,
          gender: this.form.patient.gender,
          phone: this.form.patient.phone,
          cellphone: this.form.patient.cellphone,
          email: this.form.patient.email,
          address: this.form.patient.address,
          notes: this.form.patient.notes,
          alert: this.form.patient.alert,
          cns: this.form.patient.cns ? this.form.patient.cns : null,
          insurance: {
            id: this.form.patient.insurance.id,
            record: this.form.patient.insurance.record,
            validity: this.form.patient.insurance.validity,
            planId: this.form.patient.insurance.plan.id,
          },
          motherName: this.form.patient.motherName ? this.form.patient.motherName : null,
          fatherName: this.form.patient.fatherName ? this.form.patient.fatherName : null,
        },
        insurance: {
          id: this.form.insurance.id,
          record: this.form.insurance.record || '',
          validity: this.form.insurance.validity,
          planId: this.form.insurance.plan.id,
        },
        type: this.form.type,
        status: this.form.status,
        expenses: this.form.expenses,
        arrivedAt: this.form.arrivedAt,
        notes: this.form.notes,
        slot: this.form.slot,
        reserves: this.form.reserves,
        indicated: this.form.indicated,
        meta: {
          api: false,
          origin: 'call_center',
        },
      };

      const req = this.form.id
        ? this.$http.put(`/appointments/${this.form.id}`, formData)
          .then(({ data }) => ({ data, type: 'update' }))
        : this.$http.post('/appointments', formData)
          .then(({ data }) => ({ data, type: 'create' }));

      return req
        .then(({ data }) => {
          this.form.id = data.id;
          this.form.patient.id = data.patient.id || null;
          if (this.form.patient.image.file && data.patient && data.patient.id) {
            const formImageData = new FormData();
            formImageData.append('file', this.form.patient.image.file);
            this.$http.post(`/entities/${data.patient.id}/images`, formImageData)
              .catch(() => {});
          }
          this.needToSave = false;
          return data;
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.reason) {
            const { reason } = e.response.data;
            if (reason === 'invalidExpense') {
              this.$refs['appointment-tabs'].selectTab('expenses');
              throw new Error('Procedimento desvinculado do convênio');
            } else if (reason === 'quantityOfSlotsUnavailable') {
              throw new Error('A quantidade de horários não está disponível');
            }
          }
          throw e;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    async loadPatient(id) {
      return this.$http.get(`/patients/${id}`)
        .then(({ data }) => {
          this.form.patient = mergeFrom(
            this.form.patient,
            this.formatEntity(data),
          );
        })
        .catch(() => {
          this.showError(new Error('Erro ao carregar dados do paciente'));
        });
    },
    formatEntity(data) {
      const address = data.addresses && data.addresses.length > 0
        ? data.addresses[0] : null;
      const patient = data.patient ? data.patient : null;
      const insurance = patient && patient.insurance ? patient.insurance : null;
      return {
        image: {
          file: null,
          preview: null,
          showOptions: false,
          showWebCam: false,
        },
        id: data.id,
        name: data.name,
        birthDate: data.birthDate,
        gender: data.gender,
        identity: data.identity ? data.identity : null,
        phone: data.phone,
        cellphone: data.cellphone,
        email: data.email,
        insurance: {
          id: insurance ? insurance.id : '',
          name: '',
          record: insurance ? insurance.record : '',
          validity: insurance ? insurance.validity : '',
          plan: {
            id: insurance ? insurance.planId : '',
            name: '',
          },
        },
        address: {
          id: address ? address.id : '',
          type: address ? address.type : '',
          postalCode: address ? address.postalCode : '',
          addressLine1: address ? address.addressLine1 : '',
          addressLine2: address ? address.addressLine2 : '',
          neighborhood: address ? address.neighborhood : '',
          city: address ? address.city : '',
          state: address ? address.state : '',
        },
        imageUrl: data.imageUrl,
        notes: data.notes,
        alert: data.alert,
        cns: patient ? patient.cns : '',
        motherName: patient ? patient.motherName : '',
        fatherName: patient ? patient.fatherName : '',
      };
    },
    switchTab(id) {
      this.$refs['appointment-tabs'].selectTab(id);
    },
    openConfirmationModal() {
      const professional = this.getProfessionalById(this.form.professional.id);
      const insurance = this.getInsuranceByPlanId(this.form.insurance.plan.id);
      this.confirmation.data = {
        isExam: this.form.type === 'exam',
        appointmentId: this.form.id,
        date: this.form.startDate,
        status: this.form.status,
        professionalName: professional.name,
        patientName: this.form.patient.name,
        insuranceName: insurance.customName,
        expenses: this.form.expenses.map(expenseItem => ({
          code: expenseItem.code,
          name: expenseItem.name,
          quantity: expenseItem.quantity,
          value: expenseItem.value,
        })),
      };

      this.confirmation.show = true;
    },
    showError(e) {
      let errorMessage = 'Erro não especificado';

      if (e) {
        if (e instanceof Error) {
          errorMessage = e.message;
        } else if (typeof e === 'string') {
          errorMessage = e;
        } else if (e.response && e.response.data && e.response.data.message) {
          e = e.response.data.message;
        }
      }

      this.$toast.show(errorMessage, { type: 'error', timestamp: 7000 });
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/_variables.scss';
  @import 'src/assets/scss/mixins';
  .appointments-page {
    background-color: $light-color;
    height: 100%;
    &.main-page {
      display: flex;
      .main-page-side, .main-page-content {
        padding: 0;
      }
      .main-page-side {
        flex: 0 0 13rem;
      }
      .main-page-content {
        overflow-y: auto;
        flex: 1 0;
      }
    }
  }
  #modal-appointment {
    .modal-body {
      min-height: 332px;
    }
    .btn-appointment {
      width: 1.8rem;
    }
  }
  #modal-patient-appointment {
    .filter-group {
      background-color: $gray-color-ultra-light;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      margin: 0;
      padding: $layout-spacing;
    }
    .appointment-date {
      font-weight: bold;
      padding: $layout-spacing 0;
    }
    .appointment-hour {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-column-gap: $layout-spacing;
      grid-row-gap: $layout-spacing;
      .hour {
        background-color: $gray-color-ultra-light;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        font-size: $font-size-sm;
        padding: $layout-spacing-sm;
        text-align: center;
        &:hover {
          background-color: $secondary-color;
          color: $light-color;
          cursor: pointer;
        }
      }
    }
    .has-error-message {
      background-color: lighten($error-color, 25%);
      border: lighten($error-color, 15%) solid $border-width;
      border-radius: $border-radius;
      display: inline-flex;
      font-size: $font-size-xs;
      padding: $layout-spacing-sm $layout-spacing;
      width: 100%;
    }
  }
</style>
